export const settingsIdentifiers = [
  "addEditLogLocationSkipsCustomerSelection",
  "consultantNotes",
  "companyName",
  "address",
  "cvr",
  "website",
  "contactPerson",
  "contactPhone",
  "contactMail",
  "industries",
  "customOfficeProductVariant",
  "informationPageEnabled",
  "showWhoHasNotReadInformation",
  "selectInformationvisibleTo",
  "calendarForMachineOperators",
  "machineOperatorsCanOnlySeeThemselvesOnCalendar",
  "holidaysVisibleToMachineOperators",
  "onlyManagerCreatedTasksCalendarPlanned",
  "calendarPlannedTasksBehindMeasured",
  "enableCalendarWorkTypeFilter",
  "taskAndOrderDatesFromCalendarOnCreate",
  "useCalendarWorkHours",
  "enableTransferTasks",
  "calendarShowEmployeeAlias",
  "calendarShowEmployeeName",
  "calendarShowEmployeeNumber",
  "enableEmployeeCalendarManualOrder",
  "employeeCalendarManualOrderPerUser",
  "useEmployeeGroups",
  "enableUnscheduledCalendar",
  "showUnscheduledInternalTaskCalendar",
  "hideUnscheduledCalendarForMachineOperators",
  "colorWorkTypesOnEmployeeCalendar",
  "calendarShowMaterialsWithUnits",
  "calendarShowWorkTypeNumber",
  "showFieldNumbersOnCalendar",
  "unregisteredWarningAfterMinutes",
  "taskOverlapWarningAfterMinutes",
  "showPhotoOnCalendar",
  "showProjectInsteadOfCustomer",
  "orderCalendar",
  "hideOrderCalendarTab",
  "orderCalendarFoldAllDefault",
  "colorUnscheduledCalendar",
  "orderCalendarMachineLinebreak",
  "orderCalendarShowOrderNoteLines",
  "showManagerInternalOrderNoteLinesOnOrderCalendar",
  "orderCalendarShowCreatedDate",
  "orderCalendarAsTaskCalendar",
  "taskNoteLinesOnTaskOrderCalender",
  "absenceCalendar",
  "absenceCalendarDisplayLabel",
  "machineOperatorsCanSeeAbsenceCalendar",
  "absenceCalendarBlockColor",
  "machineCalendarForMachineOperators",
  "machineCalendar",
  "geolocation",
  "googleMapsAPIKey",
  "backgroundPositionInterval",
  "googleMapsCountryRestrictions",
  "mapVisibleToAll",
  "mapVisibleToJobberRole",
  "showFieldsOnGeolocationMap",
  "showStorageOnTaskFieldMap",
  "enableTimeOverviewPage",
  "showProjectNameOnTimeOverviewPage",
  "showProjectNumberOnTimeOverviewPage",
  "availabilityWeekdays",
  "availabilityWeekdaysAskFromDay",
  "availabilityWeekdaysAskFromHour",
  "availabilityWeekends",
  "availabilityWeekendsAskFromDay",
  "autoComputedSalaryVouchers",
  "computedSalaryVoucherStartDay",
  "salaryVouchersVisibleToEmployees",
  "numberOfSalaryVouchersVisibleToEmployees",
  "salaryVouchersVisibleToManagers",
  "enableOrders",
  "orderEntryAutoOpenCustomerSelection",
  "alwaysAllowEditingOrderWorkplace",
  "orderListPageEnabled",
  "orderListColumns",
  "showOrderCreatedDateTime",
  "customerOrderListNewestFirst",
  "showDateGroupsOnOrderLists",
  "orderDrafts",
  "orderDraftsStartOnDraft",
  "orderDraftsAllwaysCreateDraft",
  "enableOrderReferenceNumber",
  "orderReferenceNumberLabel",
  "enableCustomerContacts",
  "orderEntryShowContact",
  "showManagerInternalNotesOnOrderPDF",
  "orderEntryShowWorkPlace",
  "orderEntryTaskTime",
  "enableOrderMerge",
  "autoFillReferenceNumberWithCustomerAccount",
  "alwaysAllowEditingOrderReferenceNumber",
  "alwaysAllowManagerTaskAndOrderNotesEdit",
  "orderShowCustomerFiles",
  "orderShowOrderFiles",
  "orderEntryShowPriceGroup",
  "showTimeColumnOnOrderEntryTaskList",
  "showFieldsColumnOnOrderEntryTaskList",
  "showTaskRefColumnOnOrderEntryTaskList",
  "showTaskNotesOnOrderEntryPage",
  "askForPriceGroupSelectionAfterWorkTypeSelection",
  "orderEntryShowResults",
  "orderEntryResultsEffective",
  "orderEntryResultsWorkplace",
  "orderCopyFields",
  "concurrentTasksAllowed",
  "concurrentTasksAllowedForWorkTypes",
  "concurrentTasksAllowedForDepartments",
  "oldTaskWarningAgeMinutes",
  "machineOperatorCanDeleteAssignedTasks",
  "internalTaskAutoWorkTypePreviousMachines",
  "seniorMachineOperatorCanEditNotesFromManager",
  "seniorMachineOperatorCanManageOrders",
  "taskOrderAutoOpenCustomerSelection",
  "customerTaskCreationWizard",
  "taskWizardAskForWorkPlace",
  "taskWizardFieldSelection",
  "suggestRecentlyUsedCustomers",
  "suggestRecentlyUsedWorkTypes",
  "machineOperatorsCanChooseProject",
  "switchProjectAndCustomerButtonLocation",
  "allowMachineOperatorToEditTaskDate",
  "customerTaskAutoWorkTypePreviousMachines",
  "machineBeforeMachineOperator",
  "employeeSameDayTasksWarning",
  "taskShowTimeField",
  "showArrivalAtLocationField",
  "enableTaskEstimation",
  "workplaceButtonUnderCustomer",
  "taskOrderShowDate",
  "showInvoiceNote",
  "taskOrderShowTime",
  "taskOrderTaskShowWorkPlace",
  "taskListColumns",
  "taskListeNoteLines",
  "showOrderNoteOnTaskList",
  "machineOperatorsCanSeeTheirOwnTasksAndActiveTasks",
  "machineOperatorsCanOnlySeeTheirOwnTasks",
  "daysIntoTheFutureVisibleToMachineOperator",
  "oneDayLessVisibleToMachineOperatorsBefore",
  "taskListsMachineOperatorHideCompleted",
  "taskListsMachineOperatorHideCompletedExceptToday",
  "showCreatedTimestampOnInternalTasks",
  "hideInternalTaskListWorkTypes",
  "showMachineOperatorNameOnTaskInstance",
  "showMachineOperatorInitialsOnTaskInstance",
  "showC5AccountOnTaskInstanceAnList",
  "taskCultureSubheader",
  "showWorkplaceOnTaskInstance",
  "taskInstancePDF",
  "enableDoneAtEstimateField",
  "alwaysUseLogTab",
  "ownCompanyId",
  "showLandlinePhoneNumberOnTaskInfo",
  "taskPageCreatedDate",
  "allowMachineOperatorChangeOnTaskInfoPage",
  "showTaskEffectiveTimeOnTaskInfoTab",
  "showMachineLastUse",
  "taskOrderShowContact",
  "warnIfNoWorkplace",
  "requireWorkplaceIfExists",
  "taskPickupDelivery",
  "showProjectNameOnTaskInfoTab",
  "showProjectNumberOnTaskInfoTab",
  "showProjectOnTaskInstance",
  "showOrderReferenceNumberOnTaskInfoTab",
  "materialIssuesErrors",
  "enableMaterialNoteFields",
  "noProducts",
  "materialDecimals",
  "showOursToggle",
  "allowDuplicateProductUses",
  "alternativeMaterialsMobileLayout",
  "autoSupplementingProducts",
  "showMaterialAutoLinesToEmployees",
  "autoCopyMaterialNoteToSupplementingProductNote",
  "conversionFieldDecimals",
  "productMultiSelect",
  "productImageSelection",
  "materialZeroCausesError",
  "economicMaterialProductGroups",
  "machineOperatorMayAddTaskPhotosAfterCompletion",
  "alwaysAllowManagerTaskPhotosEdit",
  "taskShowRelatedPhotos",
  "hideTasklistBillingTab",
  "machineOperaterCanEditInvoiceNote",
  "askRegardingMissingBreakOnExternalTaskCompletion",
  "taskCompleteOverview",
  "errorOnOnlyPuller",
  "missingEffectiveTimeIsError",
  "taskCompleteRepairQuery",
  "taskCompleteRepairQueryRequireNote",
  "taskCompletePrepareQuery",
  "requireFieldForCultureTasks",
  "projectMissingCompleteWarning",
  "projectMissingValidateError",
  "projectMissingValidateWarning",
  "projectMissingCompleteError",
  "hideCompleteAndMakeCopyButton",
  "includeTaskCancel",
  "completeTaskAsInternal",
  "continuationTaskTargetDate",
  "continuationCopyFields",
  "allowTakingPendingTasksFromOthers",
  "includeTaskCopy",
  "taskCopyFields",
  "taskCopyAlwaysOverridemachineOperator",
  "showContinueToNextCustomerButton",
  "enableExtraWorkButton",
  "extraWorkButtonCopiesProject",
  "internalTaskPhotos",
  "enableConvertInternalToCustomerTask",
  "askRegardingMissingBreakOnInternalTaskCompletion",
  "externalTaskCustomer",
  "hideCustomerMenuFromMachineOperators",
  "recordCustomerTasks",
  "recordInternalTasks",
  "createCustomer",
  "economicCustomerGroup",
  "economicAutogeneratedCustomerNumbers",
  "showAccountOnCustomerList",
  "showAttOnCustomerList",
  "showSearchnameOnCustomerList",
  "customerAliasEdit",
  "enableCustomerCellphone",
  "customerFileUpload",
  "displayCustomerContacts",
  "customerProjectManager",
  "customerCreateInvoicesSetup",
  "canEditCustomerVATNumber",
  "customerJournal",
  "chooseWorkTypesOnCustomerJournal",
  "contactsMayHaveCellphone",
  "customerStatsVisibleToAll",
  "customerOrderOverview",
  "sendApprovedTransportlogToCustomers",
  "sendValidatedGenericLogToCustomers",
  "showLogEmailFieldOnCustomerInstance",
  "showGenericPeriodLogView",
  "customerFields",
  "enableFieldsSettingsPage",
  "enableLocationStorage",
  "locationStoragePageVisibleToEveryone",
  "bookkeepingMachineNames",
  "bookkeepingDayLinePerTimer",
  "showNoteOnInvoiceLineTable",
  "bookkeepingWorkplaceAddressReplaceName",
  "bookkeepingWorkTypeAsWorkTypeAndVariant",
  "adjustBilledMinutes",
  "distributionTableAdjustTimeMethod",
  "bookkeepingListColumns",
  "enableGPSList",
  "showFieldsOnBookkeepingTables",
  "showLogLocationsOnBookkeeping",
  "bookkeepingDayRespectNoteLineBreaks",
  "bookkeepingDayPDF",
  "invoiceAllowRecordedWithoutInvoiceLines",
  "ifCountZeroRequireCorrectedCountZero",
  "enableInvoiceCorrections",
  "dayStartRounding",
  "dayEndRounding",
  "userListColumns",
  "anniversariesEnabled",
  "canEditAllUserDataOnProfilePage",
  "enableJobberRole",
  "jobberRoleCanCreateInternalTasks",
  "enableMechanicRole",
  "enableProjectManagerRole",
  "userCellphoneExtra",
  "userProfileEmployeeNumber",
  "employeeLabelVariant",
  "userProfileNormalTransportKilometersDefault",
  "userProfileNormalTransportMinutesDefault",
  "userProfileNormalTransportKilometers",
  "userProfileNormalTransportMinutes",
  "enableDocuments",
  "routesEnabled",
  "warnOnIncompleteRouteParts",
  "routeTaskStartStop",
  "workshopEnabled",
  "preparationWorkType",
  "workshopCreatedDateColumn",
  "workshopCreatedByColumn",
  "showWorkshopMachineName",
  "workshopWorkTypes",
  "workshopVehicleIdentificationNumber",
  "enableWorkshopChecklistsFor",
  "hideUnscheduledWorkshopTasks",
  "repairWorkType",
  "dinnerBookings",
  "lunchBookings",
  "dinnerBookingsShowThumbDialog",
  "allowSeniorMachineOperatorsToManageFoodBookings",
  "dinnerBookingLocations",
  "lunchBookingLocations",
  "foodBookingNotificationTime",
  "workTypeAutoMachines",
  "externalTaskWithoutTaskWorkTypeVariantLegal",
  "enableWorkTypePriceStructureOverviewSettingsPage",
  "canCreateExternalWorkType",
  "canEditExternalWorkType",
  "canCreatePriceGroups",
  "canEditPriceGroups",
  "onlyAllowEditingNameOnPriceGroupSettingsPage",
  "canEditInternalWorkTypes",
  "enableMachinePriceStructureOverviewSettingsPage",
  "disabledWorkTypes",
  "disabledMachines",
  "enableWorkTypeSettingsPage",
  "recordCultureTasks",
  "canCreateCultures",
  "canEditCultures",
  "externalTaskCulture",
  "hideCultureMenuFromMachineOperators",
  "enableStandardProductsSettingsPage",
  "enableStandardMachinesSettingsPage",
  "requireFavoriteMachines",
  "enableSmallMachines",
  "canCreateMachines",
  "canEditMachines",
  "overviewShowPrices",
  "canEditPriceItems",
  "priceItemConversionSetup",
  "priceItemRelationUnitConversionHelpers",
  "canEditPriceItemActive",
  "canEditPriceItemUseManualDistribution",
  "canEditPriceItemInputConditions",
  "canEditPriceItemBillable",
  "enablePriceItemMinimumCount",
  "enablePriceItemVolumeDiscount",
  "canEditPriceItemOnlyVisibleOnOverview",
  "canEditPriceItemRelevantForExecution",
  "canCreatePriceItems",
  "canEditPriceItemName",
  "canEditPriceItemID",
  "canEditPriceItemProduct",
  "canEditPriceItemUnit",
  "enableUnitSettingsPage",
  "canEditUnits",
  "fieldDefaultLocationType",
  "locationCrossCustomerSelectionEnabled",
  "locationFavoritesEnabled",
  "allowMachineOperatorToEditLocationLocationType",
  "canEditProducts",
  "machineAnalysisAccounts",
  "enableReportGenerator",
  "reportDataIsValidFrom",
  "enableNoDepartment",
  "reportNonProductiveWorkTypeNameToLatexNameMap",
  "reportExtraNonProductiveWorkTypeNameToLatexNameMap",
  "reportExtraData",
  "reportEffectiveTimeLabel",
  "reportHourDecimals",
  "reportSupportedUnits",
  "enableMachineAnalysis",
  "showMachineSalaryCostReport",
  "enableTransportReport",
  "enableRouteJournal",
  "expectedAmountEnabled",
  "expectedAmountVisibleToMachineOperators",
  "enableAppendicesPage",
  "employeeCanAccessAppendicesPage",
  "machineLabelVariant",
  "materialUseAlternativeText",
  "alwaysAllowMoreThanTwoMachines",
  "autoFillUnregisteredMinutesOnStart",
  "managerMenuTasksAfterEmployees",
  "externalTaskWithoutPriceLinesLegal",
  "onlyShowCalledInDialogForWorktypes",
  "showCalledInDialogAfterMinutes",
  "noExternalTaskWorkType",
  "showManagersUnpaidBillWarning",
  "enableExternalTaskDepartmentField",
  "departments",
  "showMachineAsWorktypeForContratorTasks",
  "treatTransportTimeAsEffectiveForDepartments",
  "onlyAdminCanChangeDepartment",
  "enableInternalTaskDepartmentField",
  "allowMoreThanTwoMachinesForDepartments",
  "onlyEnableProjectsForDepartments",
  "enableTaskReferenceNumber",
  "taskReferenceNumberLabel",
  "fullscreenCalendarSlideshow",
  "fullscreenCalendarSlideshowSeconds",
  "usePunchInOut",
  "enableProjects",
  "canEditProjects",
  "canAddProject",
  "enableProjectTravelTime",
  "enableProjectDistance",
  "canEditProjectDistance",
  "projectManagerCanManageAbsence",
  "showProjectAlias",
  "transferOrderAdminNotesToInvoice",
  "productsExcludedFromInvoice",
  "timersContributingToEffectiveTime",
  "invoicesAddTaskNotesToEffectiveTimeLine",
  "transferOrderNotesToInvoice",
  "tasksPrintout",
  "unicontaSync",
  "importExternalWorkTypesFromProductGroups",
  "importInternalWorkTypesFromProducts",
  "economicSync",
  "useApproveReport",
  "economicEnableWorkTypeFromProductGroupImport",
  "economicPriceitemProductGroupRanges",
  "economicEnableWorkplaceImport",
  "economicSyncListColumns",
  "economicTaskDepartmentImport",
  "economicWorktypeDepartmentImport",
  "economicEnableProjectActivitiesImport",
  "economicProjectsApproveTimesOnTransfer",
  "economicProjectsMachineEmployeeGroups",
  "economicProjectsMachineEmployeeNumberOffset",
  "economicProjectsMachineHoursActivity",
  "economicProjectsManHoursActivity",
  "economicProjectsManHoursMachines",
  "economicProjectsProductUseCostActivity",
  "economicProjectsProductUseCostEmployee",
  "economicInvoicePerProject",
  "economicInvoicesForProjectTasks",
  "economicInvoiceProjectNumberAndNameAsOtherReference",
  "economicProjectsIncludeNotes",
  "economicUploadWorkPlaces",
  "economicSubtractDowntimeIdentifiers",
  "economicAppendMaterialNotesToInvoiceLineDescription",
  "economicCustomerDetailsOnTransportlogOnInvoice",
  "economicInvoiceEmployeeNotes",
  "economicInvoiceIncludeTotalTaskTime",
  "economicInvoiceManagerNotes",
  "economicInvoicePerOrderReferenceNumber",
  "economicShowTaskReferenceNumber",
  "economicInvoicePerWorkplace",
  "economicInvoicePerWorkplacePickupLocationFallback",
  "economicInvoiceWorkplaceAddressReplaceName",
  "economicInvoicePullerAsDeparment",
  "economicInvoiceShowOrderWorkplaceNameAsOtherRef",
  "economicInvoiceShowTaskWorkplaceNameAsOtherRef",
  "economicMergLinesPerOrder",
  "economicSeperateDatesWithBlankLines",
  "economicSeperateTasksWithBlankLines",
  "economicSeperateNoteLines",
  "economicShowFieldUse",
  "economicShowFieldUseCrop",
  "economicShowGenericTransportlogOnInvoice",
  "economicShowMachineName",
  "economicShowMachineOperatorAlias",
  "economicShowMachineOperatorName",
  "economicShowPickupLocation",
  "economicShowTransportlogOnInvoice",
  "economicShowWorkPlaceOrDeliveryLocation",
  "economicZeroBlank",
  "economicPrefixMachineOperatorNameOrAliasWith",
  "economicRouteTaskStartStopOnInvoice",
  "c5Sync",
  "syncRecipients",
  "navSync",
  "navSyncUploadEnabled",
  "navImportCopyCustomerAccountToPhone",
  "navSyncProfile",
  "navWorktypeRanges",
  "navVariantTypeImports",
  "navImportProjects",
  "navTransferEmployeeNotes",
  "navTimersToTransferWithoutMachines",
  "brugerdataSync",
  "brugerdataSyncOnCompleted",
  "brugerdataMaterialProductGroupRanges",
  "brugerdataPriceitemProductGroupRanges",
  "brugerdataCreateOrderButton",
  "showBrugerdataOrdernumber",
  "brugerdataShowMachineOperatorAlias",
  "brugerdataSyncOnValidated",
  "checkoutHideInactive",
  "periodSplitThresholdMinutes",
  "remunerationDefaultGroup",
  "remunerationGroups",
  "remunerationIncludeEmptyDatesInReport",
  "remunerationIntervalBonusLabels",
  "remunerationNormalTransportKilometersCountBonusLabel",
  "remunerationPaidTransportWorkType",
  "remunerationRateLabels",
  "remunerationReportPerVariation",
  "remunerationReportShowAlias",
  "remunerationReportShowProjects",
  "remunerationReportShowReferenceNumbers",
  "remunerationStartRateLabel",
  "remunerationVisibleBonusLabels",
  "remunerationVisibleRateLabels",
  "remunerationWorkDayBonusLabels",
  "remunerationAbsenceTypes",
  "remunerationCountBonusLabels",
  "remunerationReportShowHolidays",
  "unregisteredBreakAfterMinutes",
  "billedBreaks",
  "registerAbsence",
  "onlyManagersCanRegisterAbsence",
  "hoursAbsenceTypes",
  "singleDayAbsenceTypes",
  "daysAbsenceTypes",
  "absenceTypeLabels",
  "absenceTypeShortLabels",
  "absenceTypeColors",
  "absenceTypesAccessibleToEmployees",
  "absenceWarningDisabledFor",
  "paidDayAbsenceTypes",
  "onlyWeekdaysAllowedAbsenceTypes",
  "enableLessorUpload",
  "enableMaanssonsSalaryUpload",
  "departmentExtraTimers",
  "hidePrimaryTimeButtonForExternalWorktypes",
  "hidePrimaryTimeButtonForInternalWorktypes",
  "machineExtraTimers",
  "workTypeMachineExtraTimers",
  "priceGroupHideTimers",
  "machinePriceGroupExtraTimers",
  "transportResponsibleEffectiveTimers",
  "workTypePriceGroupExtraTimers",
  "workTypeExtraTimers",
  "workTypeHideTimers",
  "workTypeGenericPrimaryTimerLabel",
  "workTypePriceGroupGenericPrimaryTimerLabel",
  "logWorkplaceDisplayCount",
  "logPickupButtonDisplayCount",
  "logDeliveryButtonDisplayCount",
  "logRecipients",
  "autoCreateLogPrint",
  "includeTransportlogOverviewVariant",
  "logButtonText",
  "enableTransportLog",
  "sharedTransportLog",
  "showHeaderOnTransportLog",
  "transportLogDecimals",
  "transportLogDistanceKmDecimalPlaces",
  "transportlogTimeHeader",
  "transportLogUnitPerLocation",
  "yieldLogDefaultCrop",
  "yieldLogDefaultUnit",
  "showWorkplacesInCustomerPickupDeliveryLocationDialog",
  "enableTransportLogStatistics",
  "autoAddTransportLogFor",
  "autoAddYieldLogFor",
  "autoAddSprayLogFor",
  "allowTransportlogAmountMismatch",
  "canChangeTransportLogTime",
  "dontShowTransportLogDialog",
  "effectiveTimeLabel",
  "internalTimeLabel",
  "useCustomerAddressAsDefaultTaskAddress",
  "reportDepartmentWorkTypeProductiveCombinations",
  "addNewAgricultureTaskAsDefault",
  "addNewContractorTaskAsDefaultGroup",
  "addNewContractorTaskAsDefault",
  "reportLegendShift",
  "economicShowMachineOperatorAliasOrNameWithoutLineBreak",
  "orderListNoteLines",
  "requireEmployeeAssignedToTasksWhenConvertingOrderDrafts",
  "enableCalendarDepartmentFilter",
  "showProductList",
  "showProjectNumberOnTaskInstance",
  "showTaskInvoiceNoteColumnOnOrderEntryTaskList",
  "orderCalendarBlockExtraInfo",
  "customerRequiredFields",
  "enableRecentlyUsedProducts",
  "economicEnableMachinesDepartmentSync",
  "economicEnableMachinesProjectEmployeesSync",
  "economicUploadMachinesToProjectEmployeeGroup",
  "taskCalendarFadeCompletedTasks",
  "enablePriceItemTransferAsMinutes",
  "machineOperatorCanCreateCustomerTask",
  "orderCalendarAsTaskCalendarBlockExtraInfo",
  "autoInsertSearchStringAsNewCustomerName",
  "taskShowRelatedFiles",
  "showDateGroupsOnTaskLists",
  "enableTaskInternalManagerNotes",
  "showLogPickupAndDeliveryColumnsOnOrderEntryTaskList",
  "showEmployeesAndTasksLayersToMachineOperators",
  "bookkeepingDayHideBlankPriceItems",
  "bookkeepingDayHideBlankProducts",
  "timePriceItemsBeforeOtherPriceItems",
  "workTypeBeforeTimerBeforeMachinePriceItems",
  "orderTaskTableSort",
  "showRealTimeColumnOnOrderPDF",
  "economicShowOrderReferenceNumber",
  "locationStorageJournalStatusColumn",
  "completeTaskAsInternalTargetDate",
  "emailFromAddress",
  "emailReplyToAddress",
  "logEmailText",
  "enableCustomerSwitch",
  "adminCanCreateCustomerTask",
  "enableExternalTaskWorkTypeAndVariantSwitch",
  "enableAddProducts",
  "enableTaskLogLocationChange",
  "mapTaskFormat",
  "enableOrderTaskDepartmentFilter",
  "enableOrderTaskWorkTypeFilter",
  "showTaskInfoManagerNotesAboveLogButtons",
  "showColleaguesAtCustomer",
  "showTaskArchiveToMachineOperators",
  "addMaterialNotesToProjectCostActivity",
  "durationFormat",
  "canEditProductGroups",
  "economicEnableUploadUsersToProjectEmployees",
  "economicUploadUsersToProjectEmployeeGroup",
  "allowOrderReferenceNumberEdit",
  "allowCustomerTaskEmployeeChange",
  "allowCustomerTaskMachineChange",
  "calendarPlanningFunctions",
  "setLogOnlyLocationOnCreate",
  "setWorkplaceOnlyLocationOnCreate",
  "defaultTaskEmployee",
  "showStorageProductNotOnLocationWarning",
  "enableCalendarTaskInfoPopup",
  "fuelSurcharge",
  "economicFuelSurchargeLinesAtBottom",
  "enableAccommodationAllowanceRegistrationOnInternalTasks",
  "workDaySplitThresholdMinutes",
  "autoInsertOrderDateAsNewTaskDate",
  "showInvoiceNoteOnOrderPdf",
  "barcodeScannerMachineDialog",
  "barcodeScannerProductDialog",
  "remunerationCalendarDayBonusLabels",
  "economicCompanyIdentificationNumber",
  "taskInstancePdfShowWorkplacePickupDelivery",
  "enableCalendarTaskClickWhenTaskPopupIsOpen",
  "economicPrefixCustomerContactNameWith",
  "economicShowCustomerContactName",
  "orderEntryTaskTableAllwaysShowMachinePriceGroups",
  "colorInternalTaskWorkTypesOnEmployeeCalendar",
  "absenceTypesVisibleOnFullscreenAbsenceCalendar",
  "rolesMayCreateLocations",
  "rolesMayEditLocations",
  "remunerationTaskBonusLabels",
  "fieldDialogOrFiltering",
  "economicDefaultPaymentTerms",
  "customerApprovalGate",
  "importAllFieldsPerVatNumber",
  "showOrderWorkplaceOnOrderPdf",
  "showAllProjectsOnAppendicesPage",
  "unicontaVariantDME",
  "unicontaDMEWorkingHours",
  "unicontaDMEUseTaskProjectForInternalCost",
  "unicontaMachineDimension",
  "unicontaPullerDimension",
  "unicontaManHoursMachines",
  "projectLabelVariant",
  "displayProjectsPageMenu",
  "createSalesLines",
  "createCostLines",
  "orderValidation",
  "orderValidationForWorkTypes",
  "unsetValidatedAndRecordedOnError",
  "useCorrectedCountOnCostLines",
] as const;
