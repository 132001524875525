import {Machine, isDiscontinued, urlToId} from "@co-common-libs/resources";
import {getMachineString} from "@co-common-libs/resources-utils";
import {
  DecimalField,
  IntegerField,
  ResponsiveDialog,
  TrimTextField,
} from "@co-frontend-libs/components";
import {ConnectedDepartmentDialog} from "@co-frontend-libs/connected-components";
import {
  actions,
  getCustomerSettings,
  getMachineArray,
  getUserProfileArray,
} from "@co-frontend-libs/redux";
import {
  jsonFetch,
  translateNetworkError,
  useCallWithFalse,
  useCallWithTrue,
  useResettingState,
} from "@co-frontend-libs/utils";
import {
  Button,
  CircularProgress,
  DialogContent,
  FormControlLabel,
  IconButton,
  Switch,
  useTheme,
} from "@material-ui/core";
import {
  createMachine,
  diffResourceInstanceProperties,
  getDepartmentName,
  useEventTargetCheckedCallback,
} from "app-utils";
import {globalConfig} from "frontend-global-config";
import {isEmpty, omit} from "lodash";
import CloseIcon from "mdi-react/CloseIcon";
import React, {useCallback, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {integrationCustomerSettings} from "shared-integration-customer-settings";

// TODO(mr): refactor
//            - <ResourceName>CreateDialog
//            - <ResourceName>EditDialog
//            - <ResourceName>CreateWithIntegrationDialog (add to lib in economy-system-integration feature folder)
//            - <ResourceName>EditWithIntegrationDialog (add to lib in economy-system-integration feature folder)
export const MachineCreateEditDialog = React.memo(function MachineCreateEditDialog(props: {
  machine?: Readonly<Machine> | undefined;
  onCancel: () => void;
  onOk: (machineURL: string) => void;
  open: boolean;
}): JSX.Element {
  const {machine, onCancel, onOk, open} = props;

  const [departmentDialogOpen, setDepartmentDialogOpen] = useState(false);
  const setDepartmentDialogOpenTrue = useCallWithTrue(setDepartmentDialogOpen);
  const setDepartmentDialogOpenFalse = useCallWithFalse(setDepartmentDialogOpen);

  const [name, setName] = useResettingState(machine?.name ?? "", open);
  const [active, setActive] = useResettingState(machine?.active ?? true, open);
  const [department, setDepartment] = useResettingState(machine?.department ?? "", open);
  const handleDepartmentSelected = useCallback(
    (selectedDepartment: string) => {
      setDepartment(selectedDepartment);
      setDepartmentDialogOpen(false);
    },
    [setDepartment],
  );
  const [selfPropelled, setSelfPropelled] = useResettingState(
    machine?.selfPropelled ?? false,
    open,
  );
  const [canPull, setCanPull] = useResettingState(machine?.canPull ?? false, open);
  const [smallMachine, setSmallMachine] = useResettingState(machine?.smallMachine ?? false, open);
  const [requirePhotoOnTaskCompletion, setRequirePhotoOnTaskCompletion] = useResettingState(
    machine?.requirePhotoOnTaskCompletion ?? false,
    open,
  );
  const [
    requireAtLeastOneProductUseGreaterThanZero,
    setRequireAtLeastOneProductUseGreaterThanZero,
  ] = useResettingState(machine?.requireAtLeastOneProductUseGreaterThanZero ?? false, open);
  const [hourlyTowingCostEffective, setHourlyTowingCostEffective] = useResettingState(
    machine?.hourlyTowingCostEffective ?? null,
    open,
  );
  const [hourlyTowingCostTransport, setHourlyTowingCostTransport] = useResettingState(
    machine?.hourlyTowingCostTransport ?? null,
    open,
  );
  const [machineNumber, setMachineNumber] = useResettingState(machine?.c5_machine ?? "", open);
  const [fuelConsumptionLiterPerHour, setFuelConsumptionLiterPerHour] = useResettingState(
    machine?.fuelConsumptionLiterPerHour ?? null,
    open,
  );

  const machineArray = useSelector(getMachineArray);

  const customerSettings = useSelector(getCustomerSettings);
  const {machines: machineSettings} = integrationCustomerSettings(customerSettings);

  const machineNumberDuplicate: boolean = useMemo(() => {
    if (!machineNumber) {
      return false;
    } else {
      const isAnotherMachine = ({url}: Machine): boolean => url !== machine?.url;
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const hasSameNumber = ({c5_machine}: Machine): boolean => c5_machine === machineNumber;

      return machineArray.some(
        (m) =>
          isAnotherMachine(m) &&
          hasSameNumber(m) &&
          !(machineSettings.canImport && isDiscontinued(m)),
      );
    }
  }, [machineSettings.canImport, machine?.url, machineArray, machineNumber]);

  const UserProfileArray = useSelector(getUserProfileArray);

  const machineNumberInUserOnUser: boolean = useMemo(() => {
    if (!machineNumber) {
      return false;
    } else {
      return UserProfileArray.some(
        (u) =>
          u.externalEconomicIdentifier &&
          parseInt(u.externalEconomicIdentifier) ===
            parseInt(machineNumber) + customerSettings.economicProjectsMachineEmployeeNumberOffset,
      );
    }
  }, [
    UserProfileArray,
    customerSettings.economicProjectsMachineEmployeeNumberOffset,
    machineNumber,
  ]);

  const economicSync =
    customerSettings.economicSync &&
    (customerSettings.economicEnableMachinesDepartmentSync ||
      customerSettings.economicEnableMachinesProjectEmployeesSync);

  const [saving, setSaving] = useResettingState(false, open);

  const [errorMessage, setErrorMessage] = useResettingState<string | null>(null, open);
  const intl = useIntl();

  const dispatch = useDispatch();
  const handleOk = useCallback(async () => {
    const machineData = {
      active,
      c5Machine: machineNumber,
      canPull,
      department,
      fuelConsumptionLiterPerHour,
      hourlyTowingCostEffective,
      hourlyTowingCostTransport,
      name,
      requireAtLeastOneProductUseGreaterThanZero,
      requirePhotoOnTaskCompletion,
      selfPropelled,
      smallMachine,
    };

    if (economicSync) {
      setSaving(true);
      const {baseURL} = globalConfig.resources;
      if (machine) {
        if (
          name !== machine.name ||
          active !== machine.active ||
          department !== machine.department ||
          selfPropelled !== machine.selfPropelled ||
          canPull !== machine.canPull ||
          smallMachine !== machine.smallMachine ||
          requirePhotoOnTaskCompletion !== machine.requirePhotoOnTaskCompletion ||
          requireAtLeastOneProductUseGreaterThanZero !==
            machine.requireAtLeastOneProductUseGreaterThanZero ||
          hourlyTowingCostEffective !== machine.hourlyTowingCostEffective ||
          hourlyTowingCostTransport !== machine.hourlyTowingCostTransport ||
          fuelConsumptionLiterPerHour !== machine.fuelConsumptionLiterPerHour
        ) {
          try {
            const url = `${baseURL}economic/machine/${urlToId(machine.url)}`;
            const newMachineData = omit(machineData, ["c5Machine"]);
            const response = await jsonFetch(url, "POST", newMachineData);
            dispatch(actions.addToOffline(response.data));
            onOk(response.data.url);
          } catch (error) {
            setSaving(false);
            setErrorMessage(translateNetworkError(error, intl));
          }
        } else {
          onOk(machine.url);
        }
      } else {
        const url = `${baseURL}economic/machine/create`;
        try {
          const response = await jsonFetch(url, "POST", machineData);
          dispatch(actions.addToOffline(response.data));
          onOk(response.data.url);
          setSaving(false);
          setErrorMessage(null);
        } catch (error) {
          setSaving(false);
          setErrorMessage(translateNetworkError(error, intl));
        }
      }
      setSaving(false);
    } else {
      if (machine) {
        const patch = diffResourceInstanceProperties(machineData, machine);

        if (patch.length) {
          dispatch(actions.update(machine.url, patch));
        }

        onOk(machine.url);
      } else {
        const newMachine = createMachine({
          active,
          c5_machine: machineNumber,
          canPull,
          department,
          hourlyTowingCostEffective,
          hourlyTowingCostTransport,
          name,
          requireAtLeastOneProductUseGreaterThanZero,
          requirePhotoOnTaskCompletion,
          selfPropelled,
          smallMachine,
        });

        dispatch(actions.create(newMachine));
        onOk(newMachine.url);
      }
    }
  }, [
    economicSync,
    setSaving,
    machine,
    name,
    active,
    department,
    selfPropelled,
    canPull,
    smallMachine,
    requirePhotoOnTaskCompletion,
    requireAtLeastOneProductUseGreaterThanZero,
    hourlyTowingCostEffective,
    hourlyTowingCostTransport,
    fuelConsumptionLiterPerHour,
    dispatch,
    onOk,
    setErrorMessage,
    intl,
    machineNumber,
  ]);

  const handleMachineNumberChange = useCallback(
    (value: number | null) => setMachineNumber(value?.toString() ?? ""),
    [setMachineNumber],
  );

  const handleActiveChange = useEventTargetCheckedCallback(setActive, [setActive]);
  const handleSmallMachineChanged = useEventTargetCheckedCallback(setSmallMachine, [
    setSmallMachine,
  ]);

  const handleClearDepartment = useCallback(() => {
    setDepartment("");
  }, [setDepartment]);

  const machineUpload =
    customerSettings.economicEnableMachinesDepartmentSync ||
    customerSettings.economicEnableMachinesProjectEmployeesSync;

  const {machineLabelVariant} = customerSettings;

  const theme = useTheme();
  return (
    <>
      <ResponsiveDialog
        okDisabled={!name || !machineNumber || machineNumberDuplicate || machineNumberInUserOnUser}
        open={open && !departmentDialogOpen}
        title={
          machine ? (
            machineLabelVariant === "MACHINE" ? (
              <FormattedMessage defaultMessage="Redigér maskine" />
            ) : (
              <FormattedMessage defaultMessage="Redigér køretøj" />
            )
          ) : machineLabelVariant === "MACHINE" ? (
            <FormattedMessage defaultMessage="Opret maskine" />
          ) : (
            <FormattedMessage defaultMessage="Opret køretøj" />
          )
        }
        onCancel={onCancel}
        onOk={handleOk}
      >
        <DialogContent>
          {customerSettings.economicSync && machine?.barred ? (
            <div style={{color: theme.palette.warning.main}}>
              <FormattedMessage defaultMessage="Maskinen er blevet slettet eller spærret i e-conomic" />
            </div>
          ) : null}
          {customerSettings.canEditMachines ? (
            <>
              <IntegerField
                fullWidth
                autoFocus={machine === undefined}
                disabled={machineUpload && !!machine?.remoteUrl}
                error={machineNumberDuplicate || machineNumberInUserOnUser}
                helperText={[
                  machineNumberDuplicate
                    ? intl.formatMessage({
                        defaultMessage: "Der eksisterer allerede en maskine med det maskinenummer",
                      })
                    : undefined,
                  machineNumberInUserOnUser
                    ? intl.formatMessage({
                        defaultMessage:
                          'Maskinenummeret er allerede i brug som "e-conomic projekt medarbejdernr."',
                      })
                    : undefined,
                ]
                  .filter(Boolean)
                  .join(". ")}
                inputProps={{
                  maxLength: 6,
                }}
                label={intl.formatMessage({
                  defaultMessage: "Maskinenummer *",
                })}
                margin="dense"
                value={machineNumber ? parseInt(machineNumber) : null}
                onChange={handleMachineNumberChange}
              />
              <TrimTextField
                fullWidth
                autoFocus={machine !== undefined}
                disabled={!!machine?.barred}
                label={intl.formatMessage({
                  defaultMessage: "Navn *",
                })}
                margin="dense"
                value={name}
                variant="outlined"
                onChange={setName}
              />
              <FormControlLabel
                control={<Switch checked={active} onChange={handleActiveChange} />}
                disabled={!!machine?.barred}
                label={intl.formatMessage({
                  defaultMessage: "Aktiv",
                })}
                labelPlacement="end"
              />
            </>
          ) : (
            <div>
              <h4>{getMachineString(machine)}</h4>
            </div>
          )}
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={!!selfPropelled}
                  onChange={useEventTargetCheckedCallback(setSelfPropelled, [setSelfPropelled])}
                />
              }
              disabled={
                !!machine?.barred ||
                (machine && !machineSettings.allowChangeToSelfPropelled(machine))
              }
              label={intl.formatMessage({
                defaultMessage: "Selvkørende",
              })}
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={!!canPull}
                  onChange={useEventTargetCheckedCallback(setCanPull, [setCanPull])}
                />
              }
              disabled={!!machine?.barred || !machineSettings.allowChangeToPuller}
              label={intl.formatMessage({defaultMessage: "Trækker"})}
            />
          </div>
          {customerSettings.enableSmallMachines ? (
            <div>
              <FormControlLabel
                control={<Switch checked={smallMachine} onChange={handleSmallMachineChanged} />}
                disabled={!!machine?.barred}
                label={intl.formatMessage({
                  defaultMessage: "Småmaskine",
                })}
              />
            </div>
          ) : null}
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={requireAtLeastOneProductUseGreaterThanZero}
                  onChange={useEventTargetCheckedCallback(
                    setRequireAtLeastOneProductUseGreaterThanZero,
                    [setRequireAtLeastOneProductUseGreaterThanZero],
                  )}
                />
              }
              disabled={!!machine?.barred}
              label={intl.formatMessage({
                defaultMessage:
                  "Kræv at der er tilføjet mindst én vare på opgaven og at denne er udfyldt med en værdi større end 0",
              })}
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={!!requirePhotoOnTaskCompletion}
                  onChange={useEventTargetCheckedCallback(setRequirePhotoOnTaskCompletion, [
                    setRequirePhotoOnTaskCompletion,
                  ])}
                />
              }
              disabled={!!machine?.barred}
              label={
                machineLabelVariant === "MACHINE"
                  ? intl.formatMessage({
                      defaultMessage:
                        "Kræv billede ved opgaveudførsel hvor denne maskine er anvendt",
                    })
                  : intl.formatMessage({
                      defaultMessage:
                        "Kræv billede ved opgaveudførsel hvor dette køretøj er anvendt",
                    })
              }
            />
          </div>
          {customerSettings.enableMachineAnalysis ? (
            <>
              <DecimalField
                fullWidth
                decimalPlaces={2}
                disabled={!!machine?.barred || (!canPull && !selfPropelled)}
                label={intl.formatMessage({
                  defaultMessage: "Omkostning pr. time, bugsering (effektiv)",
                })}
                margin="dense"
                maxDigits={6}
                value={hourlyTowingCostEffective}
                onChange={setHourlyTowingCostEffective}
              />
              <DecimalField
                fullWidth
                decimalPlaces={2}
                disabled={!!machine?.barred || (!canPull && !selfPropelled)}
                label={intl.formatMessage({
                  defaultMessage: "Omkostning pr. time, bugsering (transport)",
                })}
                margin="dense"
                maxDigits={6}
                value={hourlyTowingCostTransport}
                onChange={setHourlyTowingCostTransport}
              />
            </>
          ) : null}
          {customerSettings.fuelSurcharge === "KR_PER_LITER" ? (
            <DecimalField
              fullWidth
              decimalPlaces={3}
              disabled={!!machine?.barred}
              label={intl.formatMessage({
                defaultMessage: "Brændstofforbrug, liter per time",
              })}
              margin="dense"
              maxDigits={10}
              value={fuelConsumptionLiterPerHour}
              onChange={setFuelConsumptionLiterPerHour}
            />
          ) : null}
          {!isEmpty(customerSettings.departments) && (
            <div>
              <h4>
                <FormattedMessage defaultMessage="Auto-afdeling" />
              </h4>
              <Button
                color="primary"
                disabled={!!machine?.barred}
                variant="contained"
                onClick={setDepartmentDialogOpenTrue}
              >
                <FormattedMessage defaultMessage="Vælg" />
              </Button>
              <br />
              {getDepartmentName(department || "", customerSettings)}
              <IconButton disabled={!!machine?.barred} onClick={handleClearDepartment}>
                <CloseIcon />
              </IconButton>
            </div>
          )}
          <FormattedMessage defaultMessage="* Skal udfyldes" tagName="div" />
          {errorMessage ? (
            <h3
              style={{
                color: theme.palette.error.main,
                whiteSpace: "pre-line",
              }}
            >
              {errorMessage}
            </h3>
          ) : null}
          {saving ? (
            <div style={{textAlign: "center"}}>
              <CircularProgress />
            </div>
          ) : null}
        </DialogContent>
      </ResponsiveDialog>
      <ConnectedDepartmentDialog
        open={departmentDialogOpen}
        onCancel={setDepartmentDialogOpenFalse}
        onOk={handleDepartmentSelected}
      />
    </>
  );
});
